import {NftRewardPrize, NftRewardPrizeType} from "@devour/client";
import {ReactElement} from "react";
import FrameButton from "@/components/buttons/FrameButton";
import {BiGift} from "react-icons/bi";
import {RewardNftRedemptionState} from "@/components/goVip/GoVipRewardsSelectedNft";
import {useNavigate} from "react-router";
import useThemePreference from "@/hooks/useThemePreference";
import useThirwebWallet from "@/hooks/useThirwebWallet";

interface Props {
    toggleConfirmModal: (open: boolean) => void;
    redemptionState: RewardNftRedemptionState;
    toggleDrawer: () => void;
    prize?: NftRewardPrize;
}

export default function GoVipRewardsSelectedNftFooter(props: Props): ReactElement {
    const navigate = useNavigate();
    const { isOnDarkMode } = useThemePreference();
    const { onOpenThirdwebWallet } = useThirwebWallet();

    function renderDefaultFooter(): ReactElement {
        return (
            <div className="govip-rewards-drawer_footer_default">
                <FrameButton
                    color={isOnDarkMode
                        ? "white-drop-shadow-devour"
                        : "purple-outline"}
                    size="large"
                    leftIcon={BiGift}
                    className="govip-rewards-drawer_footer_default_button"
                    onClick={() => props.toggleConfirmModal(true)}
                >
                    Redeem Now
                </FrameButton>
                <div
                    className="govip-rewards-drawer_footer_default_transfer"
                    onClick={onOpenThirdwebWallet}
                >
                    Transfer from DevourGO Wallet

                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/govip-nft-rewards-drawer-arrow.svg`}
                        alt="arrow icon"
                    />
                </div>
            </div>
        );
    }

    function renderRedemptionInProgressFooter(): ReactElement {
        return (
            <div className="govip-rewards-drawer_footer_in-progress">
                Unlocking
                <div className="loading-dot" />
                <div className="loading-dot" />
                <div className="loading-dot" />
            </div>
        );
    }

    function renderPrizeRevealFooter(): ReactElement {
        return (
            <div className="govip-rewards-drawer_footer_default">
                {props.prize?.type === NftRewardPrizeType.FUEL
                    ? <>
                        <FrameButton
                            color="purple-outline"
                            size="large"
                            className="govip-rewards-drawer_footer_default_button"
                            onClick={() => navigate("/withdraw")}
                        >
                            View My FUEL Balance
                        </FrameButton>
                        <div
                            className="govip-rewards-drawer_footer_default_transfer"
                            onClick={props.toggleDrawer}
                        >
                            Close
                        </div>
                    </>
                    : <FrameButton
                        color="purple-outline"
                        size="large"
                        className="govip-rewards-drawer_footer_default_button"
                        onClick={props.toggleDrawer}
                    >
                        Confirm and close
                    </FrameButton>
                }
            </div>
        );
    }

    return (
        <>
            {props.redemptionState === RewardNftRedemptionState.NOT_INITIATED && renderDefaultFooter()}
            {props.redemptionState === RewardNftRedemptionState.IN_PROGRESS && renderRedemptionInProgressFooter()}
            {props.redemptionState === RewardNftRedemptionState.DONE && renderPrizeRevealFooter()}
        </>
    );

}