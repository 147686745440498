import {GetNftRewardResponse, NftOwnership, NftRewardPrize, NftRewardPrizeType, NftsApi, Token} from "@devour/client";
import { ReactElement, useEffect, useState } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import GoVipRedeemNftConfirmModal from "@/components/modals/GoVipRedeemNftConfirmModal";
import Toast from "@/components/Toast";
import GoVipRewardsSelectedNftFooter from "@/components/goVip/GoVipRewardsSelectedNftFooter";
import classNames from "classnames";
import { IStore } from "@/redux/defaultStore";
import { addError } from "@/redux/meta/metaActions";
import { useGetRedeemableNftOwnerships } from "@/hooks/useGetRedeemableNftOwnerships";
import { useGetNftOwnershipsForUser } from "@/hooks/useGetNftOwnershipsForUser";
import { GoVipRewardsNftTooltip } from "./GoVipRewardsNftTooltip";
import getConfig from "@/utils/getConfig";
import useRefreshRewardData from "@/hooks/useRefreshRewardData";

interface Props {
    reward: GetNftRewardResponse;
    ownership: NftOwnership;
    toggleDrawer: () => void;
}

export enum RewardNftRedemptionState {
    NOT_INITIATED,
    IN_PROGRESS,
    DONE,
}

function GoVipRewardsSelectedNft(props: Props) {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { reward } = props;
    const rewardMetadata = reward.nftReward;
    const prizes = reward.prizes;

    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [prizeWon, setPrizeWon] = useState<NftRewardPrize>(undefined);
    const [redemptionState, setRedemptionState] = useState<RewardNftRedemptionState>(RewardNftRedemptionState.NOT_INITIATED);
    const [revealPrize, setRevealPrize] = useState<boolean>(false);
    const { refetch: refetchRedeemableNftOwnerships } = useGetRedeemableNftOwnerships(fullToken as Token);
    const { refetch: refetchNftOwnerships } = useGetNftOwnershipsForUser(fullToken as Token);
    const {refetch: refreshRewardData} = useRefreshRewardData();
    const sparklingEffectTimeout: number = rewardMetadata.transitionEffectEnabled ? 1500 : 0;
    const rewardImage =
        rewardMetadata.image?.url ?? `${import.meta.env.VITE_CDN_URL}/images/govip-nft-rewards-default-image.gif`;

    useEffect(() => {
        resetProgress();
    }, []);

    function resetProgress(): void {
        setPrizeWon(undefined);
        setRedemptionState(RewardNftRedemptionState.NOT_INITIATED);
        setRevealPrize(false);
        setShowToast(false);
    }

    function toggleRedeemConfirmModal(open: boolean = false): void {
        setShowConfirmModal(open);
    }

    function onConfirmRedeem(): void {
        setShowToast(true);
        setShowConfirmModal(false);
        setRedemptionState(RewardNftRedemptionState.IN_PROGRESS);
    }

    async function onTransactionConfirmed(transactionHash: `0x${string}`): Promise<void> {
        let prize: NftRewardPrize;
        try {
            const prizeWon = await new NftsApi(getConfig(fullToken)).redeemNftReward({
                redeemNftRewardBody: {
                    transactionHash: transactionHash,
                    nftRewardMetadataId: rewardMetadata.id,
                },
            });
            prize = prizeWon.nftPrize;
            setRevealPrize(true);
            setShowToast(false);
            setTimeout(() => {
                setPrizeWon(prize);
                setRedemptionState(RewardNftRedemptionState.DONE);
            }, sparklingEffectTimeout);
        } catch (e) {
            resetProgress();
            dispatch(await addError(e));
        } finally {
            await refreshRewardData(prize.type);
            void refetchRedeemableNftOwnerships();
            void refetchNftOwnerships();
        }
    }

    function handleToastDismissal(): void {
        setShowToast(false);
    }

    function renderTooltipContent(): ReactElement {
        const outsideContent: ReactElement =
            <div className="govip-rewards-drawer_reward_tooltip_label">
                <BsFillInfoCircleFill />
                What's inside?
            </div>;
        return (
            <GoVipRewardsNftTooltip
                title={
                    <>
                        <BsFillInfoCircleFill />
                        What's Inside the Loot Box?
                    </>
                }
                description="Each prize in our collection is accompanied by a specified quantity limit. A prize will be randomly
                    selected from below."
                prizes={prizes}>
                {outsideContent}
            </GoVipRewardsNftTooltip>
        );
    }

    function renderPrizeInfo(): ReactElement {
        const today = new Date();
        const month = today.toLocaleString("en-us", { month: "short" });
        const day = today.getDate();
        const year = today.getFullYear();
        const {prizeRevealName: prizeName, description, type, amount, nftTracker} = prizeWon;
        let descriptionText = description;
        if (!descriptionText) {
            descriptionText = `You have successfully redeemed ${rewardMetadata.nftTracker.name} NFT and claimed ${prizeName}.`;
            switch (type) {
                case NftRewardPrizeType.FUEL:
                    descriptionText += ` ${amount} ${import.meta.env.VITE_TOKEN_NAME} have been loaded onto your ${import.meta.env.VITE_TOKEN_NAME} Account. Enjoy!`;
                    break;
                case NftRewardPrizeType.XP:
                    descriptionText += ` ${prizeName} XP have been added to your account. Enjoy!`;
                    break;
                case NftRewardPrizeType.NFT:
                    descriptionText += nftTracker ? ` ${nftTracker.name} NFT has been added to your collection. Enjoy!` : "";
            }
        }

        return (
            <div className="govip-rewards-drawer_reward_info">
                <div className="govip-rewards-drawer_reward_info_origin">
                    <span>Prize</span>
                </div>
                <div className="govip-rewards-drawer_reward_info_title">{prizeName}</div>
                <div className="govip-rewards-drawer_reward_info_nftId">{`Redeemed on ${month} ${day}, ${year}`}</div>
                <div className="govip-rewards-drawer_reward_info_description">{descriptionText}</div>
            </div>
        );
    }

    function getRewardImageUrl(): string {
        return redemptionState === RewardNftRedemptionState.NOT_INITIATED || !rewardMetadata.redemptionAnimation
            ? rewardImage
            : rewardMetadata.redemptionAnimation.url;
    }

    return (
        <>
            <Toast
                message="Please DO NOT refresh! We are getting your prize ready."
                isOpen={showToast}
                showButton={false}
                onDismiss={handleToastDismissal}
                variant="warning"
                remainIndefinitely={true}
                removeMarginAdjustment={true}
            />
            <GoVipRedeemNftConfirmModal
                isOpen={showConfirmModal}
                contractAddress={rewardMetadata.nftTracker.contractAddress}
                nftId={props.ownership.nftId}
                onTransactionSubmit={onConfirmRedeem}
                onTransactionConfirmed={onTransactionConfirmed}
                toggle={toggleRedeemConfirmModal}
            />
            <div className="govip-rewards-drawer_reward">
                <div className="govip-rewards-drawer_reward_top">
                    <div
                        className={classNames("govip-rewards-drawer_reward_top_image-container", "winning-image", {
                            show: prizeWon,
                        })}
                    >
                        {prizeWon &&
                            <img
                                src={prizeWon.winningImage?.url ?? rewardImage}
                                alt="NFT Prize"
                                className="prize-img"
                            />
                        }
                    </div>

                    <div
                        className={classNames("govip-rewards-drawer_reward_top_image-container", "reward-image", {
                            show: !prizeWon,
                        })}
                    >
                        <img src={getRewardImageUrl()} alt="NFT Reward" />

                        <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/govip-rewards-light-effect.gif`}
                            alt="Light Effect"
                            className={classNames("light-effect", {
                                show: revealPrize && !prizeWon && rewardMetadata.transitionEffectEnabled,
                            })}
                        />
                    </div>

                    {!revealPrize && renderTooltipContent()}
                </div>
                {prizeWon
                    ? renderPrizeInfo()
                    : <div className="govip-rewards-drawer_reward_info">
                        <div className="govip-rewards-drawer_reward_info_origin">
                            <span>{rewardMetadata.origin}</span>
                        </div>
                        <div className="govip-rewards-drawer_reward_info_title">{rewardMetadata.title}</div>
                        <div className="govip-rewards-drawer_reward_info_nftId">{`#${props.ownership.nftId}`}</div>
                        <div className="govip-rewards-drawer_reward_info_description">{rewardMetadata.description}</div>
                    </div>
                }
            </div>

            <div className="govip-rewards-drawer_footer">
                <GoVipRewardsSelectedNftFooter
                    toggleConfirmModal={toggleRedeemConfirmModal}
                    redemptionState={redemptionState}
                    prize={prizeWon}
                    toggleDrawer={props.toggleDrawer}
                />
            </div>
        </>
    );
}

export default GoVipRewardsSelectedNft;
